angular.module('private.editor.edit', [

    'ui.router',
    'smart-table',
    'ui.tinymce',
    'private.resources',
    'shared',
    'templates'

]).config(function ($stateProvider, $urlRouterProvider) {

    $stateProvider.state('private.editor.edit', {
        url: '/edit/:entity/:id',
        templateUrl: 'private.editor.edit',
        ncyBreadcrumb: {
            parent: 'private.editor',
            label: '{{entity.entity}} {{entity.name}}'
        },
        resolve: {
            entity: function ($stateParams, EditorEntity) {
                console.log('resolve', $stateParams);
                return EditorEntity.get($stateParams);
            }
        },
        controller: 'PrivateEditorEditController'
    });

}).controller('PrivateEditorEditController', function ($scope, $state, $filter, entity) {

    console.log('PrivateEditorEditController');

    $scope.loading = true;
    $scope.error = false;
    $scope.dmp = new diff_match_patch();
    $scope.properties = [{
        column: 'title',
        title: 'Заголовок'
    }, {
        column: 'content',
        title: 'Текст'
    }];
    $scope.show = {
        ru: true,
        ua: true,
        pl: true,
        diffs: true,
        title: true,
        content: true,
        published: -1,
        master: -1,
        editor: false,
        tinymce: {
            //trusted: true,
            inline: false,
            plugins: 'autoresize advlist autolink link image lists charmap print preview',
            width: '100%',
            //height: 50,
            autoresize_on_init: true,
            autoresize_min_height: 1,
            autoresize_max_height: 100,
            autoresize_bottom_margin: 1,
            //autoresize_overflow_padding: 0,
            skin: 'lightgray',
            theme: 'modern',
            menubar: false,
            statusbar: false,
            setup: function (editor) {
                editor.on('change', function (e) {
                    //console.log('');
                });
                editor.on('focus', function () {
                    $(this.contentAreaContainer.parentElement).find("div.mce-toolbar-grp").show();
                });
                editor.on('blur', function () {
                    $(this.contentAreaContainer.parentElement).find("div.mce-toolbar-grp").hide();
                });
                editor.on("init", function () {
                    $(this.contentAreaContainer.parentElement).find("div.mce-toolbar-grp").hide();
                });
            }
        }
    };
    $scope.reload = reload;
    $scope.setMaster = setMaster;
    $scope.setPublished = setPublished;
    $scope.remove = remove;
    $scope.copy = copy;
    $scope.save = save;

    reload();

    function reload() {
        entity.$promise.then(
            function (entity) {
                $scope.entity = entity;
                //$scope.expandLocales($scope.entity.editions);
                var published = entity.editions.findIndex(function (edition) {
                    return edition.id == $scope.entity.edition_id;
                });
                $scope.show.published = published;
                $scope.setMaster(published != -1 ? published : entity.editions.length - 1);
                $scope.loading = false;
            },
            function (error) {
                $scope.error = error;
                $scope.loading = false;
            }
        );
    }

    function setMaster(master) {
        $scope.show.master = master;
        var lhs = $scope.entity.editions[master];
        angular.forEach($scope.entity.editions, function (rhs, i) {
            if (!rhs.diff) {
                rhs.diff = [];
            }
            if (!rhs.diff[master]) {
                rhs.diff[master] = {};
                angular.forEach($scope.properties, function (property) {
                    rhs.diff[master][property.column] = {};
                    angular.forEach($scope.locales, function (locale) {
                        var diff = $scope.dmp.diff_main(
                            lhs[property.column][locale.code],
                            rhs[property.column][locale.code]
                        );
                        $scope.dmp.diff_cleanupSemantic(diff);
                        var html = $scope.dmp.diff_prettyHtml(diff);
                        //console.warn('====================');
                        //console.log(lhs[property.column][locale.code]);
                        //console.log(rhs[property.column][locale.code]);
                        //console.warn('--------------------');
                        //console.log(diff);
                        rhs.diff[master][property.column][locale.code] = {
                            edited: diff.length > 1,
                            html: html
                        };
                    });
                });
            }
        });
    }

    function setPublished(published) {
        BossEntity.save({
            id: $stateParams.id,
            edition_id: $scope.entity.editions[published].id
        }).$promise.then(
            function (response) {
                console.log('update() OK. Setting $scope.show.published to', published);
                $scope.show.published = published;
                $scope.loading = false;
            },
            function (error) {
                console.log('update() ERROR. Leaving $scope.show.published at', $scope.show.published);
                $scope.error = error;
                $scope.loading = false;
            }
        );
    };

    function remove(index) {
        BossEntityEdition.remove({
            id: $scope.entity.editions[index].id
        }).$promise.then(
            function (response) {
                console.log('delete() OK');
                $scope.reload();
                $scope.loading = false;
            },
            function (error) {
                console.log('delete() ERROR');
                $scope.error = error;
                $scope.loading = false;
            }
        );
    }

    function copy(index) {
        $scope.new_edition = angular.copy($scope.entity.editions[index]);
        $scope.show.editor = true;
    };

    function save() {
        var edition = angular.copy($scope.new_edition);
        delete edition.diff;
        EditorEntityEdition.save(edition).$promise.then(
            function (response) {
                console.log('Save OK:', response);
                $scope.show.editor = false;
                $scope.reload();
            },
            function (error) {
                console.log('Save ERROR:', error);
            }
        )
    };

});
