angular.module('private.boss', [
    'ui.router',
    'private.resources',
    'templates'
]).config(function ($stateProvider, $urlRouterProvider) {
        $stateProvider
            .state('private.boss', {
                url: '/boss',
                templateUrl: 'private.boss',
                controller: function ($scope) {

                }

            })
    }
);