angular.module('private.profile', [
    'ui.router',
    'private.resources',
    'templates'
]).config(function ($stateProvider, $urlRouterProvider) {
        $stateProvider
            .state('private.profile', {
                url: '/profile',
                templateUrl: 'private.profile',
                controller: function ($scope) {

                }
            })
    }
);