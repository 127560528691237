angular.module('private.logout', [

    'ui.router',
    'shared.session',
    'public.home'

]).config(function ($stateProvider, $urlRouterProvider) {

    $stateProvider.state('private.logout', {
        url: '/logout',
        templateUrl: 'private.logout',
        controller: 'PrivateLogoutController'
    })

}).controller('PrivateLogoutController', function(Session, $timeout, $state) {

    Session.destroy();
    $timeout(function() {
        $state.go('public.home');
    }, 1000);

});