angular.module('private.editor.list', [

    'ui.router',
    'smart-table',
    'ui.tinymce',
    'private.resources',
    'shared',
    'templates'

]).config(function ($stateProvider, $urlRouterProvider) {

    $stateProvider.state('private.editor.list', {
        url: '/list',
        templateUrl: 'private.editor.list',
        ncyBreadcrumb: {
            label: 'Редактор'
        },
        resolve: {
        },
        controller: 'PrivateEditorListController'
    });

}).controller('PrivateEditorListController', function ($scope, $state) {

    console.log('PrivateEditorListController');

});

