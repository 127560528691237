angular.module('private.cabinet', [

    'ui.router',
    'private.resources',
    'templates'

]).config(function ($stateProvider, $urlRouterProvider) {

    $stateProvider.state('private.cabinet', {
        url: '',
        templateUrl: 'private.cabinet',
        ncyBreadcrumb: {
            label: 'Кабинет'
        },
        controller: 'PrivateCabinetController'
    })

}).controller('PrivateCabinetController', function ($scope) {

    $scope.raiseError = function () {
        var x = -1, y = Math.sqrt2(x);
        throw new Error('blah');
    };

    $scope.getDayClass = function (date, mode) {
        //var date = data.date;
        //var mode = data.mode;
        var cls = '';
        if (mode == 'day') {
            switch (date.getDate()) {
                case 20:
                    cls = 'task-red';
                    break;
                case 14:
                    cls = 'task-blue';
                    break;
                case 19:
                    cls = 'task-green';
                    break;
            }
            //console.log(date, cls);
        }
        return cls;
    }

});