angular.module('private.resources', [

    'ngResource'

]).factory('cabinet', function ($resource) {
    return $resource('/api/cabinet/:id');
}).factory('EditorEntity', function ($resource) {
    return $resource('/api/editor/:entity/:id', {entity: '@entity', id: '@id'}, {
        get: {
            method: 'GET',
            params: {entity: '@entity', id: '@id'}
        }
    });
}).factory('BossEntity', function ($resource) {
    return $resource('/api/boss/entities/:id', {id: '@id'}, {
        save: {
            method: 'PUT'
        }
    });
}).factory('EditorEntityEdition', function ($resource) {
    return $resource('/api/editor/entity-editions/:id');
}).factory('BossEntityEdition', function ($resource) {
    return $resource('/api/boss/entity-editions/:id');
});