angular.module('private', [

    'ui.router',
    'satellizer',
    'ui.bootstrap.popover',
    'shared.session',
    'private.profile',
    'private.cabinet',
    'private.boss',
    'private.editor',
    'private.logout',
    'templates'

]).config(function ($stateProvider) {

    $stateProvider.state('private', {
        abstract: true,
        url: '/private',
        templateUrl: 'private',
        resolve: {
            session: function (Session) {
                console.log('private.resolve.session()');
                return Session.get();
            }
        },
        onEnter: function(Session) {
            console.log('private.onEnter()');
            //Session.authenticate();
        },
        controller: 'PrivateController'
    });

}).controller('PrivateController', function ($scope, $state, session) {

    console.log('PrivateController');

    initMenu();
    //checkAuthenticated();

    function initMenu() {
        $scope.menu = [{
            state: 'affiliate',
            title: 'Партнер'
        }, {
            state: 'study',
            title: 'Работа',
            submenu: [{
                state: 'principal',
                title: 'Ректор'
            }, {
                state: 'teacher',
                title: 'Преподаватель'
            }, {
                state: 'secretary',
                title: 'Секретарь'
            }, {
                state: 'student',
                title: 'Студент'
            }]
        }, {
            state: 'forum',
            title: 'Форум',
            submenu: [{
                state: 'guest',
                title: 'Гость'
            }, {
                state: 'member',
                title: 'Участник'
            }, {
                state: 'moderator',
                title: 'Модератор'
            }, {
                state: 'administrator',
                title: 'Администратор'
            }]
        }, {
            state: 'boss',
            title: 'Босс',
            submenu: [{
                state: 'users',
                title: 'Пользователи'
            }]
        }];
    }

    //function checkAuthenticated() {
    //    User.get().$promise.then(
    //        function (user) {
    //
    //            //var forum_roles = ['guest', 'member'];
    //            //var special_roles = user.roles.filter(function (role) {
    //            //    return forum_roles.indexOf(role.name) < 0;
    //            //});
    //            //$scope.roles = special_roles;
    //            $scope.user = user;
    //
    //        }, function (error) {
    //            console.log('PrivateController: not authenticated, redirecting to login', error);
    //            $state.go('public.login');
    //        }
    //    );
    //}
});
