angular.module('private.editor.list2', [

    'ui.router',
    'smart-table',
    'ui.tinymce',
    'private.resources',
    'shared',
    'templates'

]).config(function ($stateProvider, $urlRouterProvider) {

    $stateProvider.state('private.editor.list2', {
        url: '/list',
        templateUrl: 'private.editor.list.OLD',
        ncyBreadcrumb: {
            label: 'Редактор'
        },
        resolve: {
            entities: function(EditorEntity) {
                return EditorEntity.query();
            }
        },
        controller: 'PrivateEditorListController'
    });

}).controller('PrivateEditorListController', function ($scope, $state, entities) {

    console.log('PrivateEditorListController');
    $scope.entities = entities;
    $scope.entitiesSafe = [];
    $scope.itemsPerPage = 20;
    $scope.loading = true;
    $scope.error = false;
    $scope.filter = {
        published: [true, true],
        edited: [true, true]
    };

    entities.$promise.then(
        function (result) {
            $scope.entitiesSafe = result;
            $scope.entities = [].concat($scope.entitiesSafe);
            $scope.loading = false;
        },
        function (error) {
            $scope.error = error;
            $scope.loading = false;
        }
    );

    $scope.go = function (id) {
        console.log(id);
        $state.go('private.editor.entity', {id: id});
    };

});

