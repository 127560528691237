angular.module('private.editor', [

    'ui.router',
    'smart-table',
    'ui.tinymce',
    'private.resources',
    'private.editor.list',
    'private.editor.edit',
    'shared',
    'templates'

]).config(function ($stateProvider, $urlRouterProvider) {

    $stateProvider.state('private.editor', {
        url: '/editor',
        abstract: true,
        template: '<ui-view></ui-view>',
        ncyBreadcrumb: {
            label: 'Редактор'
        },
        controller: 'PrivateEditorController'
    });

}).controller('PrivateEditorController', function ($scope, $state, EditorEntity) {

    console.log('PrivateEditorController');
    return;

});
